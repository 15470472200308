<template>
  <v-container fluid class="width-80" v-if="getPermission('setting:view')">
    <v-layout>
      <v-flex>
        <h3
          class="font-weight-900 mb-4 font-size-36 custom-headline color-custom-blue"
        >
          Calendar Settings
        </h3>
      </v-flex>
      <v-flex class="text-right">
        <v-btn
          class="custom-grey-border custom-bold-button"
          v-on:click="goBack"
        >
          <v-icon small left>mdi-keyboard-backspace</v-icon>
          Back
        </v-btn>
      </v-flex>
    </v-layout>
    <perfect-scrollbar
      :options="{ suppressScrollX: true }"
      class="scroll custom-box-top-inner-shadow"
      style="max-height: 85vh; position: relative"
    >
      <v-card
        flat
        class="custom-setting-height custom-grey-border remove-border-radius"
      >
        <v-card-title class="headline grey lighten-4">
          <v-row>
            <v-col md="6" class="py-0 my-auto">
              <h3
                class="font-weight-700 custom-headline color-custom-blue"
                style="margin: 3.5px 0px"
              >
                <!--  Service # -->
              </h3>
            </v-col>
            <template v-if="getPermission('setting:update')">
              <v-col md="6" class="text-right py-0">
                <v-btn
                  small
                  class="text-white"
                  color="cyan"
                  v-on:click="updateCalendarSetting"
                  :disabled="pageLoading"
                  :loading="pageLoading"
                >
                  <span class="font-size-16 font-weight-600">Update</span>
                </v-btn>
              </v-col>
            </template>
          </v-row>
        </v-card-title>
        <v-card-text
          class="p-6 font-size-16"
          :class="{ 'pointer-events-none': !getPermission('setting:update') }"
        >
          <v-row>
            <v-col md="12" class="py-0">
              <v-container fluid class="py-0">
                <v-card
                  flat
                  class="custom-grey-border remove-border-radius"
                  style="min-height: 700px"
                >
                  <v-card-title class="headline grey lighten-4 py-2">
                  </v-card-title>
                  <v-card-text class="p-6">
                    <template>
                      <table class="width-100 font-size-14">
                        <tr>
                          <td
                            class="custom-border-right custom-border-top py-3 custom-font-weight"
                            width="30%"
                          >
                            Visit
                          </td>
                          <td
                            class="custom-border-right custom-border-top py-3"
                            width="70%"
                          >
                            <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              id="job-type"
                              :items="visitlist"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Type"
                              v-model="calendarSetting.visit"
                              solo
                              flat
                              item-color="cyan"
                              placeholder="Type"
                              item-text="text"
                              item-value="value"
                              hide-details
                              multiple
                              class="width-100"
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  small
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  @click="data.select"
                                  @click:close="remove(data.item, 'visit')"
                                >
                                  {{ data.item.text }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-chip small>
                                        {{ data.item.text }}
                                      </v-chip>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td
                            class="custom-border-right custom-border-top py-3 custom-font-weight"
                            width="30%"
                          >
                            Delivery
                          </td>
                          <td
                            class="custom-border-right custom-border-top py-3"
                            width="70%"
                          >
                            <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              id="job-type"
                              :items="deliverylist"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Type"
                              v-model="calendarSetting.delivery"
                              solo
                              flat
                              item-color="cyan"
                              placeholder="Type"
                              item-text="text"
                              item-value="value"
                              hide-details
                              multiple
                              class="width-100"
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  small
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  @click="data.select"
                                  @click:close="remove(data.item, 'visit')"
                                >
                                  {{ data.item.text }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-chip small>
                                        {{ data.item.text }}
                                      </v-chip>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </td>
                        </tr>
                        <!--  <tr>
                          <td
                            class="custom-border-right custom-border-top py-3 custom-font-weight"
                          >
                            Quotation
                          </td>
                          <td
                            class="custom-border-right custom-border-top py-3"
                          >
                            <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              id="job-type"
                              :items="quotationlist"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Type"
                              solo
                              v-model="calendarSetting.quotation"
                              flat
                              item-color="cyan"
                              placeholder="Type"
                              item-text="text"
                              item-value="value"
                              hide-details
                              multiple
                              class="width-100"
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  small
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  @click="data.select"
                                  @click:close="remove(data.item, 'quotation')"
                                >
                                  {{ data.item.text }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-chip small>
                                        {{ data.item.text }}
                                      </v-chip>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </td>
                        </tr> -->
                        <tr v-if="false">
                          <td
                            class="custom-border-right custom-border-top py-3 custom-font-weight"
                          >
                            Contract
                          </td>
                          <td
                            class="custom-border-right custom-border-top py-3"
                          >
                            <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              id="job-type"
                              :items="contractlist"
                              :disabled="pageLoading"
                              v-model="calendarSetting.contract"
                              :loading="pageLoading"
                              label="Type"
                              solo
                              flat
                              item-color="cyan"
                              placeholder="Type"
                              item-text="text"
                              item-value="value"
                              hide-details
                              multiple
                              class="width-100"
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  small
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  @click="data.select"
                                  @click:close="remove(data.item, 'contract')"
                                >
                                  {{ data.item.text }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-chip small>
                                        {{ data.item.text }}
                                      </v-chip>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td
                            class="custom-border-right custom-border-top py-3 custom-font-weight"
                          >
                            Project
                          </td>
                          <td
                            class="custom-border-right custom-border-top py-3"
                          >
                            <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              id="job-type"
                              :items="projectlist"
                              :disabled="pageLoading"
                              v-model="calendarSetting.project"
                              :loading="pageLoading"
                              label="Type"
                              solo
                              flat
                              item-color="cyan"
                              placeholder="Type"
                              item-text="text"
                              item-value="value"
                              hide-details
                              multiple
                              class="width-100"
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  small
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  @click="data.select"
                                  @click:close="remove(data.item, 'project')"
                                >
                                  {{ data.item.text }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-chip small>
                                        {{ data.item.text }}
                                      </v-chip>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </td>
                        </tr>
                        <tr v-if="false">
                          <td
                            class="custom-border-right custom-border-top py-3 custom-font-weight"
                          >
                            Leave
                          </td>
                          <td
                            class="custom-border-right custom-border-top py-3"
                          >
                            <v-autocomplete
                              dense
                              color="cyan"
                              filled
                              id="job-type"
                              v-model="calendarSetting.leave"
                              :items="leavelist"
                              :disabled="pageLoading"
                              :loading="pageLoading"
                              label="Type"
                              solo
                              flat
                              item-color="cyan"
                              placeholder="Type"
                              item-text="text"
                              item-value="value"
                              hide-details
                              multiple
                              class="width-100"
                            >
                              <template v-slot:selection="data">
                                <v-chip
                                  small
                                  v-bind="data.attrs"
                                  :input-value="data.selected"
                                  close
                                  @click="data.select"
                                  @click:close="remove(data.item, 'leave')"
                                >
                                  {{ data.item.text }}
                                </v-chip>
                              </template>
                              <template v-slot:item="data">
                                <template>
                                  <v-list-item-content>
                                    <v-list-item-title>
                                      <v-chip small>
                                        {{ data.item.text }}
                                      </v-chip>
                                    </v-list-item-title>
                                  </v-list-item-content>
                                </template>
                              </template>
                            </v-autocomplete>
                          </td>
                        </tr>
                      </table>
                    </template>
                  </v-card-text>
                </v-card>
              </v-container>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </perfect-scrollbar>
  </v-container>
</template>

<script>
import { GET, POST } from "@/core/services/store/request.module";

export default {
  name: "profile-service-setting",
  data() {
    return {
      pageLoading: false,
      visitlist: [
        {
          value: "name",
          text: "Id",
        },
        {
          value: "Title",
          text: "Title",
        },
        {
          value: "customer",
          text: "Customer",
        },
      ],
      deliverylist: [
        {
          value: "name",
          text: "Id",
        },
        {
          value: "Title",
          text: "Title",
        },
        {
          value: "customer",
          text: "Customer",
        },
      ],
      quotationlist: [
        {
          value: "name",
          text: "Id",
        },
        {
          value: "Title",
          text: "Title",
        },
        {
          value: "customer",
          text: "Customer",
        },
      ],
      contractlist: [
        {
          value: "name",
          text: "Id",
        },
        {
          value: "Title",
          text: "Title",
        },
        {
          value: "customer",
          text: "Customer",
        },
      ],
      projectlist: [
        {
          value: "name",
          text: "Id",
        },
        {
          value: "Title",
          text: "Title",
        },
        {
          value: "customer",
          text: "Customer",
        },
      ],
      leavelist: [
        {
          value: "category",
          text: "Leave Type",
        },
        {
          value: "start",
          text: "Start Date",
        },
        {
          value: "end",
          text: "End Date",
        },
        {
          value: "engineer",
          text: "User",
        },
      ],
      calendarSetting: {
        visit: [],
        delivery: [],
        quotation: [],
        contract: [],
        project: [],
        leave: [],
      },
      setting: {
        prefix: null,
        next_number: null,
        number_length: null,
        description: null,
      },
    };
  },
  methods: {
    getCalendarSetting() {
      const _this = this;
      _this.$store
        .dispatch(GET, { url: "setting/calendar-setting-list" })
        .then(({ data }) => {
          _this.calendarSetting = data;
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
    remove(item, type) {
      if (type == "visit") {
        if (this.calendarSetting.visit.length > 1) {
          const index = this.calendarSetting.visit.indexOf(item.value);
          this.calendarSetting.visit.splice(index, 1);
        }
        return false;
      } else if (type == "delivery") {
        if (this.calendarSetting.delivery.length > 1) {
          const index = this.calendarSetting.delivery.indexOf(item.value);
          this.calendarSetting.delivery.splice(index, 1);
        }
        return false;
      } else if (type == "quotation") {
        if (this.calendarSetting.quotation.length > 1) {
          const index = this.calendarSetting.quotation.indexOf(item.value);
          this.calendarSetting.quotation.splice(index, 1);
        }
        return false;
      } else if (type == "contract") {
        if (this.calendarSetting.contract.length > 1) {
          const index = this.calendarSetting.contract.indexOf(item.value);
          this.calendarSetting.contract.splice(index, 1);
        }
        return false;
      } else if (type == "project") {
        if (this.calendarSetting.project.length > 1) {
          const index = this.calendarSetting.project.indexOf(item.value);
          this.calendarSetting.project.splice(index, 1);
        }
        return false;
      } else if (type == "leave") {
        if (this.calendarSetting.leave.length > 1) {
          const index = this.calendarSetting.leave.indexOf(item.value);
          this.calendarSetting.leave.splice(index, 1);
        }
        return false;
      }
    },
    updateCalendarSetting() {
      const _this = this;
      _this.pageLoading = true;
      _this.$store
        .dispatch(POST, {
          url: "setting/calendar-setting",
          data: _this.calendarSetting,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.pageLoading = false;
        });
    },
  },
  mounted() {
    this.getCalendarSetting();
  },
};
</script>
